@keyframes slide-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.logo-slider {
    mask-image: linear-gradient(to right, transparent 0, white 10px, white calc(100% - 10px), transparent 100%);
}

.logo-track {
    animation: slide-left 200s linear infinite;
    white-space: nowrap;
}

.logo-slider:hover .logo-track {
    animation-play-state: paused;
}

.logo-img {
    height: 40px;
}

.payment-logo {
    height: 30px;
}