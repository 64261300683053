h1,
h2,
h3,
h4 {
  font-family: 'IBM Plex Sans', sans-serif;
}

.navbar {
  z-index: 100;
}

#review,
#steps,
#somos,
#flags {
  color: #005066;
}

#footer {
  color: #DCECEF;
}


.bg-custom-green {
  background-color: #005066 !important;
}

.navbar-custom {
  border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  -ms-border-radius: 0 0 10px 10px;
  -o-border-radius: 0 0 10px 10px;
}

@media (max-width: 767px) {
  .navbar-mobile .nav-link {
    font-weight: bold;
    text-align: center;
    color: #89BDC7 !important;
    margin-top: 10px;
    transition: background-color 0.5s ease 0.5s;
    -webkit-transition: background-color 0.5s ease 0.5s;
    -moz-transition: background-color 0.5s ease 0.5s;
    -ms-transition: background-color 0.5s ease 0.5s;
    -o-transition: background-color 0.5s ease 0.5s;
  }

  .navbar-mobile .navbar-nav {
    width: 100%;
    justify-content: center;
  }

  .navbar-mobile .nav-button {
    display: block;
    width: 75%;
    margin-top: 10px;
    transition: background-color 0.5s ease 0.5s;
    -webkit-transition: background-color 0.5s ease 0.5s;
    -moz-transition: background-color 0.5s ease 0.5s;
    -ms-transition: background-color 0.5s ease 0.5s;
    -o-transition: background-color 0.5s ease 0.5s;
  }

  .navbar-mobile-expanded .navbar-toggler {
    color: #0082A6;
    transition: color 0.5s ease;
    -webkit-transition: color 0.5s ease;
    -moz-transition: color 0.5s ease;
    -ms-transition: color 0.5s ease;
    -o-transition: color 0.5s ease;
  }

  .navbar-mobile .navbar-collapse {
    transition: height 0.5s ease;
    -webkit-transition: height 0.5s ease;
    -moz-transition: height 0.5s ease;
    -ms-transition: height 0.5s ease;
    -o-transition: height 0.5s ease;
  }

  .navbar-mobile .navbar-collapse.show {
    transition-delay: 0.5s;
  }
}


.text-inter {
  font-family: 'Inter', sans-serif;
}

.color-text {
  color: #0082A6;
}

.hero-background {
  background-image: url('/public/images/hero-background.jpeg');
  background-position: center;
  background-size: cover;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
}

.contact-background {
  background-image: url('/public/images/contact-background.jpeg');
  background-position: center;
  background-size: cover;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
}

.contact-background>*,
.hero-background>* {
  position: relative;
  z-index: 2;
  color: white;
}

.blu-background-filter::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #005066;
  opacity: 0.7;
  z-index: 1;
}

/* Media query para dispositivos móviles */
@media (max-width: 768px) {
  .home {
    background-size: cover !important;
    background-repeat: no-repeat;
  }
}

.custom-img-size {
  width: 6.25rem;
  height: auto;
}


.color-somos {
  color: #006885;
}

.no-border {
  border: none !important;
  box-shadow: none !important;
}


.text-steps {
  font-family: 'IBM Plex Sans', sans-serif;
  color: #3F4446;
}

.text-step {
  font-family: 'IBM Plex Sans', sans-serif !important;
  color: #005066 !important;
}

.color-steps {
  color: #005066 !important;
}

.color-review {
  color: #3F4446 !important;
}

.button-contact {
  background-color: #ECEEEE !important;
  color: #000 !important;
  border: none !important;
}

.text-gray {
  color: #3F4446 !important;
}

.carousel-button {
  padding: 0.6rem 4rem !important;
}

.divider-solid-blue {
  color: #0082a6;
}

.post-p-small {
  font-size: 16px;
  color: #3f4446;
  font-weight: 500;
}

.post-title-h1-primary {
  font-size: 45px;
  line-height: 45px;
  font-weight: 600;
  color: #0082a6;
}

.post-title-h2-primary {
  font-size: 30px;
    line-height: 30px;
    font-weight: 400;
    color: #0082a6;
}

.post-p {
  font-size: 25px;
  line-height: 35px;
  color: #3f4446;
  font-weight: 400;
}

.post-p-header {
  font-size: 16px;
  color: #3f4446;
}


.text-like {
  color: #005066 !important;
  /* Tomará el color del elemento padre o puedes especificar un color */
  text-decoration: none !important;
  /* Quita el subrayado */
}

.text-footer {
  color: #0082A6 !important;
  text-decoration: none !important;
  /* Quita el subrayado */
}


.text-menu {
  color: #006885 !important;
}


.hover-overlay::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 116, 0);
  transition: background-color 0.3s ease;
  pointer-events: none;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  -ms-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
}

.image-container:hover .hover-overlay::after {
  background-color: rgba(255, 255, 116, 0.5);
  /* Color amarillo con transparencia al hacer hover */
}

.card-hover::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 116, 0);
  transition: background-color 0.3s ease;
  pointer-events: none;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  -ms-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
}

.step-indicator {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
}

.step-text {
  font-family: 'IBM Plex Sans', sans-serif;
  color: rgba(63, 68, 70, 0.5);
  font-size: 15px;
  font-weight: 600;
  line-height: 22.5px;
  text-align: left;
}

.step-text.completed {
  color: #0082A6;
}

.step-text.active {
  color: #005066;
}



.step-bubble {
  padding: 0.2rem 0.7rem;
  border: 1px solid rgba(63, 68, 70, 0.5);
  border-radius: 60%;
  color: rgba(63, 68, 70, 0.5);
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  -ms-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
}

.step-bubble.active {
  background-color: #005066;
  border: none;
  color: #DCECEF;
}

/* If the step is completed, you may want to add different styling, e.g., */
.step-bubble.completed {
  background-color: #0082A6;
  border: none;
  color: #DCECEF
    /* Green bg for completed steps */
}

.step-arrow {
  margin: 0 8px;
  color: #333;
  font-weight: normal;
}

/* Base style for smaller screens (below 1280px) */
.p-quote {
  padding: 3em;
}

/* Enhanced style for larger screens (between 1280px and 1920px) */
@media (min-width: 1280px) and (max-width: 1920px) {
  .p-quote {
    padding: 9em;
  }
}


.hero-primary-text {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 105px;
  font-weight: 700;
  line-height: 90px;
  letter-spacing: -0.02em;
}

.hero-secondary-text {
  font-family: 'Inter', sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
}

.bg-green-light-cu {
  background-color: #CADEE3;
}

.bg-green-light-cu-2 {
  background-color: #DCECEF;
}

.bg-white-light-cu {
  background-color: #F5F9FA;
}

.divider-solid {
  border-top: 2px solid rgba(63, 68, 70, 0.7);
  width: 5.375rem;
}

/* custom theme */
.btn-primary {
  background-color: #0082A6 !important;
  border-color: #0082A6 !important;
}

.btn-outline-light {
  color: #EEEEEE !important;
  border-color: #EEEEEE !important;
}

.btn-outline-light:hover {
  background-color: #eeeeeea8 !important;
}

.btn-outline-light:hover>* {
  color: #ffffff00 !important;
}


.btn {
  font-family: 'IBM Plex Sans', sans-serif !important;
  padding: 1rem 1.5rem;
  border-radius: 0.625rem !important;
  -webkit-border-radius: 0.625rem !important;
  -moz-border-radius: 0.625rem !important;
  -ms-border-radius: 0.625rem !important;
  -o-border-radius: 0.625rem !important;
}

.nav-link {
  color: #DCECEF !important;
  font-size: 0.75rem !important;
}

.form-control {
  padding: 0.69rem 1.5rem !important;
  min-width: 20rem;

}

.form-control:focus {
  border-color: #005066 !important;
  box-shadow: none !important;
}

.form-control::placeholder {
  color: #5b8794 !important;
}

.btn-form {
  padding: 0.69rem 1.5rem !important;
}

.thanks-title {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 45px;
  font-weight: 600;
  line-height: 67.5px;
  text-align: left;
  color: #005066;
}

.thanks-text {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #00506680;

}

div.card {
  border: 0 !important;
  background-color: #ffffff00;
  color: none !important;
}

.card-title {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 600 !important;
  line-height: 28px;
  text-align: left !important;
  color: #005066 !important;
}

.card-title-text-post {
  font-family: 'Inter', sans-serif;
    font-size: 25px;
    font-weight: 600 !important;
    line-height: 28px;
    text-align: left !important;
    color: #005066 !important;
}

.card-title.card-title-black {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 25px !important;
  color: #3F4446 !important;
}

.form-control.input-outline {
  background-color: #ffffff00 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.form-control.input-outline::placeholder {
  color: #DCECEF !important;
}

.card-text {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 300 !important;
  line-height: 24px;
  text-align: left !important;
  color: #005066 !important;
}

.card-text.card-text-sm {
  font-size: 15px !important;
  line-height: 18px !important;
  text-align: left;
}

.bg-card-blue-dark {
  background-color: #cadee3 !important;
}

.bg-card-blue-light {
  background-color: #f5f9fa !important;
}

.form-tittle {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #005066;
}

.text-green{
  color: #005066 !important;
} 

.form-label {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 18px;
  font-weight: 410;
  line-height: 22px;
  text-align: left;
  color: #00506680;
}

.row>* {
  margin: 0 !important;
  padding: 0 !important;
}


@media (max-width: 991px) {
  .form-control {
    min-width: 100% !important;

  }

  #hero {
    margin-top: -1rem !important;
    width: 100% !important;
    height: auto !important;
    padding: 3rem 0 !important;

    div {
      padding: 0 2rem !important;
      margin: 0 !important;
      gap: 1rem !important;

      h1 {
        font-size: 3.125rem !important;
        line-height: 3.125rem !important;
      }

      p {
        margin: 0 !important;
        padding: 0 !important;
        font-size: 1.375rem !important;
        line-height: 1.563rem !important;
      }
    }
  }

  #thanks {
    width: 100% !important;
    height: auto !important;
    padding: 1.5rem !important;

    div {
      margin: 0 !important;
      padding: 0 !important;

      div {
        margin: 0 !important;
        padding: 1rem !important;

        div {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    }
  }

  .btn {
    width: 100% !important;
    font-size: 1.2rem !important;
    line-height: 1.875rem !important;
  }

  .thanks-title {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 2.5rem !important;
    line-height: 2.7rem !important;
    text-align: center;
  }

  #cotizar {
    width: 100% !important;
    height: auto !important;

    div {
      margin: 0 !important;
      padding: 0 !important;

      div {
        margin: 0 !important;
        padding: 1rem !important;

        form {
          margin: 0 !important;
          padding: 0 !important;
          gap: 1rem !important;

          div {
            margin: 0 !important;
            padding: 0 !important;
            gap: 1rem !important
          }

          h3 {
            padding: 0 !important;
            font-size: 1.25rem !important;
            line-height: 1.875rem !important;
          }
        }
      }
    }
  }

  #flags {
    div {
      h1 {
        font-size: 1.6rem !important;
        line-height: 1.8rem !important;
      }
    }
  }

  #somos {
    div {
      h1 {
        font-size: 2.3rem !important;
        line-height: 2.4rem !important;
      }

      p {
        font-size: 1.5rem !important;
        line-height: 1.5rem !important;
      }

      #somosRow {
        width: 100% !important;
        height: auto !important;
        margin: 0 !important;
        gap: 1rem !important;

        #somosCol {
          width: 100% !important;
          height: auto !important;

          #somosCard {
            div {
              div.card-title {
                font-size: 1.5rem !important;
                line-height: 1.5rem !important;
              }

              p.card-text {
                font-size: 1.2rem !important;
                line-height: 1.5rem !important;
              }
            }
          }

        }
      }

    }
  }

  #steps {
    #stepsImg {
      width: 100% !important;
      height: auto !important;
    }

    div {
      width: 100% !important;
      height: auto !important;

      div {
        h1 {
          font-size: 2.6rem !important;
          line-height: 2.6rem !important;
          text-align: left;
        }
      }

      #stepsRow {
        width: 100% !important;
        height: auto !important;
        margin: 0 !important;

        #stepsCol {
          width: 100% !important;
          height: auto !important;

          #stepsCard {
            width: 100% !important;
            height: auto !important;

            div.card-title {
              font-size: 1.7rem !important;
              line-height: 1.6rem !important;
            }

            p.card-text {
              font-size: 1.4rem !important;
              line-height: 1.5rem !important;
            }
          }
        }

      }

    }
  }

  #review {
    #reviewRow {
      width: 100% !important;
      height: auto !important;
      margin: 0 !important;

      #reviewCol {
        width: 100% !important;
        height: auto !important;

        #reviewCard {
          width: 100% !important;
          height: auto !important;

          div.card-title {
            font-size: 1.7rem !important;
            line-height: 1.6rem !important;
          }

          p.card-text {
            font-size: 0.9rem !important;
            line-height: 1.5rem !important;
          }
        }
      }
    }
  }

  #contact {
    width: 100% !important;
    height: auto !important;

    div {
      width: 100% !important;
      height: auto !important;

      div .hero-primary-text {
        font-size: 4.6rem !important;
        line-height: 4.6rem !important;
        text-align: left !important;
      }
    }
  }

  #footer {
    width: 100% !important;
    height: auto !important;

    input {
      width: 100% !important;
      margin: 0 !important;
      padding-block: 1rem !important;
    }

    div {
      width: 100% !important;
      height: auto !important;

      div {
        h1 {
          font-size: 1.6rem !important;
          line-height: 1.6rem !important;
        }
      }
    }
  }
}